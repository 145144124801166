import gql from 'graphql-tag';
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class MachineService {
    readQuery(){
        var query = `
            query ($startDate:String!, $endDate:String!, $machineId:Int!) {
                GetItemMachineProductionReport(start_date:$startDate, end_date:$endDate, machine_id:$machineId) {
                    item_machine_production_detail {
                        item_id
                        item_name
                        product_code
                        result_weight
                        waste_weight
                        loss_weight
                        result_quantity
                        machine_id
                        machine_name
                        machine_code
                        machine_type_name
                        machine_type_code
                    }
                } 
            }
        `;
        return query;
    }
    
    async getTotalCountQuery(variables){
        var query = gql`
            query ($startDate:String!, $endDate:String!, $machineId:Int!) {
                GetItemMachineProductionReport(start_date:$startDate, end_date:$endDate, machine_id:$machineId) {
                    total_production
                    total_result_weight
                    total_waste_weight
                    total_loss_weight
                    total_result_quantity
                    total_count_downtime
                    
                    item_machine_production_detail {
                        item_id
                        item_name
                        product_code
                        result_weight
                        waste_weight
                        loss_weight
                        result_quantity
                        machine_id
                        machine_name
                        machine_code
                        machine_type_name
                        machine_type_code
                    }
                } 
            }
        `;
        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        return result.data.GetItemMachineProductionReport;
    }

    async getMachineQuery(){
        var query = gql`
            query {
                GetMachine {
                    machine {
                        machine_id
                        machine_code
                        machine_name
                    }
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];

        if (result.data.GetMachine != null) {
            for (let i = 0; i < result.data.GetMachine.machine.length; i++) {
                var str = { 
                    value: result.data.GetMachine.machine[i].machine_id, 
                    label: result.data.GetMachine.machine[i].machine_code + ' (' + result.data.GetMachine.machine[i].machine_name + ')'
                }
                arrayData.push(str);
            }
        }
        return arrayData;
    }   

    machineReportExcelHeaderSize(){
        var size = [
            {width: 25}, //colA
            {width: 25}, //colB
            {width: 25}, //colC
            {width: 25}, //colD
            {width: 25}, //colE
            {width: 25}, //colF
        ];
        return size;
    }

    machineReportExcel(data, variables){
        var arrayObject = [];
        var date = 'Tanggal ' + moment(variables.startDate).format('DD MMM YYYY') + ' - ' + moment(variables.endDate).format('DD MMM YYYY');
        var numberFormat = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"_);_(@_)';

        var startIndex = 6;
        var lastIndex = startIndex;

        if(data != null){
            lastIndex = lastIndex + data.length - 1;
        }

        //Header
        var row1 = {colA : 'PT BOJONG WESTPLAS',};
        arrayObject.push(row1);

        var row2 = {colA : 'Maintenance Report',};
        arrayObject.push(row2);

        var row3 = {colA : date};
        arrayObject.push(row3);

        var spaceRow = {colA : null};
        arrayObject.push(spaceRow);

        var row6 = {
            colA : 'KODE BARANG',
            colB : 'NAMA BARANG',
            colC : 'TOTAL BERAT',
            colD : 'TOTAL WASTE',
            colE : 'TOTAL LOSS',
            colF : 'QUANTITY JADI',
        };
        arrayObject.push(row6);

        if(data != null){
            for (let i = 0; i < data.length; i++){
                var row = {
                    colA : data[i].product_code,
                    colB : data[i].item_name,
                    colC : { v: data[i].result_weight, z: numberFormat },
                    colD : { v: data[i].waste_weight, z: numberFormat },
                    colE : { v: data[i].loss_weight, z: numberFormat },
                    colF : { v: data[i].result_quantity, z: numberFormat },
                };
                
                arrayObject.push(row);
            }
        }

        return arrayObject;
    }
}


export default new MachineService();