<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Reporting Mesin</h2>
            </CCardHeader>
            <CCardBody>
                <CRow>
                    <CCol class="sm-3">
                        <CRow class="pb-0">
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold">Tipe Tanggal</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <CSelect id="DateType" class="font-weight-bold" @change="onChangeDateType()" :options="DateTypeData" :value.sync="DateType" />
                                <label id="errorDateType" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>

                        <CRow class="pb-3">
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold">Tanggal Mulai</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <date-picker
                                    v-model="DateFrom"
                                    format="DD MMM YYYY"
                                    type="date"
                                    placeholder="Pilih tanggal"
                                    value-type="YYYY-MM-DD"
                                    style="width:100%"
                                    v-bind:clearable="false"
                                    @change = "onChangeDateFrom"
                                >
                                </date-picker>
                                <label id="errorDateFrom" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>

                        <CRow class="pb-0">
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Tanggal Akhir</label>
                                <label class="form-label font-weight-bold" style="float:right;">:&nbsp;</label>
                            </CCol>                            
                            <CCol class="sm-3" >
                                <date-picker
                                    v-model="DateTo"
                                    format="DD MMM YYYY"
                                    type="date"
                                    placeholder="Pilih tanggal"
                                    value-type="YYYY-MM-DD"
                                    style="width:100%;"
                                    v-bind:clearable="false"
                                    @change = "onChangeDateTo"
                                ></date-picker>
                                <label id="errorDateTo" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                    </CCol>

                    <CCol class="sm-3">
                        <CRow class="pb-0">
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold">Mesin</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <CSelect id="Machine" class="font-weight-bold" @change="onChangeMachine()" :options="MachineData" :value.sync="Machine" />
                                <label id="errorMachine" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>

                        <CRow class="pb-3">
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold">Tipe Mesin</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <label class="form-label font-weight">{{MachineType}}</label>
                            </CCol>
                        </CRow>

                        <CRow class="pb-0">
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold">Lama Downtime</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <label class="form-label font-weight">{{CountDowntime}}</label>
                            </CCol>
                        </CRow>
                    </CCol>

                    <CCol class="sm-3">
                        <div style="border:1px solid lightgrey;padding:10px">
                            <CRow>
                                <CCol>
                                    <CRow>
                                        <CCol class="sm-4">
                                            <label class="form-label font-weight">Total Berat Produksi</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-3">
                                            <label class="form-label font-weight">{{TotalProductionWeight}}</label>
                                        </CCol>
                                    </CRow>

                                    <CRow>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">Total Berat Waste</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{TotalWasteWeight}}</label>
                                        </CCol>
                                    </CRow>
                                    
                                    <CRow>
                                        <CCol class="sm-1">
                                                <label class="form-label font-weight">Total Loss</label>
                                                <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                            </CCol>
                                            <CCol class="sm-1">
                                                <label class="form-label font-weight">{{TotalLoss}}</label>
                                        </CCol>
                                    </CRow>

                                    <CRow>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">Total Produksi</label>
                                            <label class="form-label font-weight" style="float:right">:&nbsp;</label>
                                        </CCol>
                                        <CCol class="sm-1">
                                            <label class="form-label font-weight">{{TotalProduction}}</label>
                                        </CCol>
                                    </CRow>
                                </CCol>
                            </CRow>
                        </div>
                    </CCol>
                </CRow>
                
                <hr>
                <CRow >
                    <CCol class="sm-3">
                        <button id="view" class="btn btn-primary" style="float:left"
                            @click="exportExcel()">
                            <i class="fa fa-file-excel-o"></i> Excel
                        </button>
                    </CCol>
                </CRow>
                <hr>
                
                <CRow>
                    <CCol>
                        <machine-report-grid ref = "machineReportGrid"
                            :key="gridReload" 
                            :dateTo="DateToChangest" 
                            :dateFrom="DateFromChangest" 
                            :machine="MachineChangest"
                        />
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import 'vue2-datepicker/index.css';
import moment from 'moment';
import datePicker from 'vue2-datepicker';
import { report, datetime_zone } from '../../../infrastructure/constant/variable';

import MachineReportGrid from '../MachineReport/Grid/MachineReportGrid.vue';
import MachineReportServices from '../MachineReport/Script/MachineReportServices.js';

export default {
    name: 'TransactionInvoicePaymentReport',
    components: {
        'machine-report-grid': MachineReportGrid,
        'date-picker': datePicker
    },
    data(){
        return{
            gridReload: 0,

            DateFrom: report.default_start_date,
            DateTo: report.default_end_date,

            DateFromChangest: report.default_start_date,
            DateToChangest: report.default_end_date,

            DateTypeData: [],
            DateType: report.default_date,

            Machine: '',
            MachineData: [],
            MachineChangest: '',

            MachineType: '',
            CountDowntime: 0,
            TotalProductionWeight: 0,
            TotalLoss: 0,
            TotalWasteWeight: 0,
            TotalProduction: 0,
        }
    },
    async mounted () {
        this.DateTypeData = this.$globalfunc.getDateTypeData();

        this.MachineData = await MachineReportServices.getMachineQuery();
        this.Machine = this.MachineData[0].value;

        this.onChangeDateType();
    },
    methods: {
        async onChangeMachine(){
            this.DateFromChangest = this.DateFrom;
            this.DateToChangest = this.DateTo;
            this.MachineChangest = this.Machine;
            
            var variables = {
                startDate: this.DateFromChangest + datetime_zone.date_from,
                endDate: this.DateToChangest + datetime_zone.date_to,
                machineId: this.MachineChangest == '' ? 0 : parseInt(this.MachineChangest),
            };
            await this.getTotalCount(variables);

            this.gridReload++;
        },
        async onChangeDateType(){
            var dateTypeData = this.$globalfunc.getDateTypeDetail(this.DateType);

            this.DateFrom = dateTypeData.startDate == null ? this.DateFrom : dateTypeData.startDate;
            this.DateTo = dateTypeData.endDate == null ? this.DateTo : dateTypeData.endDate;

            this.DateFromChangest = this.DateFrom;
            this.DateToChangest = this.DateTo;
            this.MachineChangest = this.Machine;
            
            var variables = {
                startDate: this.DateFromChangest + datetime_zone.date_from,
                endDate: this.DateToChangest + datetime_zone.date_to,
                machineId: this.MachineChangest == '' ? 0 : parseInt(this.MachineChangest),
            };
            await this.getTotalCount(variables);

            this.gridReload++;
        },
        async getTotalCount(variables){            
            var data = await MachineReportServices.getTotalCountQuery(variables);
            
            this.TotalProductionWeight = data.total_result_weight;
            this.TotalWasteWeight = data.total_waste_weight;
            this.TotalLoss = data.total_loss_weight;
            this.TotalProduction = data.total_production;
            this.CountDowntime = data.total_count_downtime;

            this.MachineType = data.item_machine_production_detail[0].machine_type_name;
        },
        async onChangeDateFrom(){
            var vue = this;
            if ((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0) {
                this.DateFrom = this.DateFromChangest;
                vue.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
            }
            else {
                this.DateFromChangest = this.DateFrom;
                this.DateToChangest = this.DateTo;                
                this.MachineChangest = this.Machine;
            
                var variables = {
                    startDate: this.DateFromChangest + datetime_zone.date_from,
                    endDate: this.DateToChangest + datetime_zone.date_to,
                    machineId: this.MachineChangest == '' ? 0 : parseInt(this.MachineChangest),
                };
                await this.getTotalCount(variables);                

                this.gridReload++;
                this.DateType = 'Custom';
            }
        },
        async onChangeDateTo() {
            var vue = this;
            if ((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0) {
                this.DateTo = this.DateToChangest;
                vue.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
            }
            else {
                this.DateFromChangest = this.DateFrom;
                this.DateToChangest = this.DateTo;                
                this.MachineChangest = this.Machine;
            
                var variables = {
                    startDate: this.DateFromChangest + datetime_zone.date_from,
                    endDate: this.DateToChangest + datetime_zone.date_to,
                    machineId: this.MachineChangest == '' ? 0 : parseInt(this.MachineChangest),
                };
                await this.getTotalCount(variables);        
                
                this.gridReload++;
                this.DateType = 'Custom';
            }
        },
        async exportExcel() {
            this.$refs.machineReportGrid.exportExcel();
        },
    }
}
</script>

<style scoped>
</style>