<template>
    <div>
        <kendo-grid
            ref="grid"
            :data-source="MaintenanceReport"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true"
        >
        </kendo-grid>
    </div>
</template>
  
  
<script>
import moment from 'moment';
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import { kendo_grid, datetime_zone } from '../../../../infrastructure/constant/variable.js';

import MaintenanceReportServices from '../Script/MaintenanceReportServices.js';
  
export default {
    name: 'MaintenanceReportGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['dateTo', 'dateFrom', 'machine'],
    computed: {
        MaintenanceReport () {
            var dateFrom = this.$props.dateFrom;
            var dateTo = this.$props.dateTo;
            var machine = this.$props.machine;

            return new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                serverFiltering: false,
                serverSorting: false,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const variables = {
                                startDate: dateFrom + datetime_zone.date_from,
                                endDate: dateTo + datetime_zone.date_to,
                                machineId: parseInt(machine),
                            };
                            return {
                                query: MaintenanceReportServices.readQuery(),
                                variables: variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) { 
                        if(response.data.GetMaintenanceReport == null) {
                            return [];
                        }
                        else {
                            return response.data.GetMaintenanceReport;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetMaintenanceReport == null) {
                            return 0;
                        }
                        else {
                            return response.data.GetMaintenanceReport.length;
                        }
                    },
                    model: {
                        fields: {
                            maintenance_date: { type:"date" },
                            maintenance_end_date: { type:"date" },
                            quantity: { type:"number" },
                            downtime: { type:"number" },
                        }
                    }                
                },
                group: [ {field: "maintenance_code"}] 
            });
        }
    },
    data: function () {
        return  {
            columns:  [
                { field: "maintenance_code", title: "Kode Maintenance", width: 200, hidden:true, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    groupHeaderTemplate: "Kode Maintenance : #= value #",
                },
                { field: "maintenance_type", title: "Tipe Maintenance", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
                { field: "downtime", title: "Downtime", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    attributes:{ style:"text-align:right;" }
                },
                { field: "maintenance_date", title: "Tanggal Maintenance", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    attributes:{ style:"text-align:center;" },
                    template: "#= kendo.toString(kendo.parseDate(maintenance_date, 'yyyy-MM-dd'), 'dd-MM-yyyy') #" 
                },
                { field: "maintenance_end_date", title: "Tanggal Selesai Maintenance", width: 250, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    attributes:{ style:"text-align:center;" },
                    template: "#= maintenance_end_date != null ? kendo.toString(kendo.parseDate(maintenance_end_date, 'yyyy-MM-dd'), 'dd-MM-yyyy') : '' #" 
                },
                { field: "product_code", title: "Kode Barang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
                { field: "item_name", title: "Nama Barang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
                { field: "quantity", title: "Quantity", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    attributes:{ style:"text-align:right;" }
                },
                { field: "type_detail", title: "Tipe Detail", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
                { field: "notes", title: "Catatan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
                { field: "approved_by", title: "Approve Oleh", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.client_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    methods: {
        async exportExcel(DateFrom, DateTo, Machine) {
            const variables = {
                startDate: DateFrom,
                endDate: DateTo,
                machineId: parseInt(Machine)
            };

            var gridData = this.$refs.grid.kendoWidget()._data;
            var data = await MaintenanceReportServices.maintenanceReportExcel(gridData, variables);
            if (gridData == null) {
                this.$swal("Error", response.noRecord, "error")
            }
            else {
                var XLSX = require("xlsx");
                var dateNow = moment(new Date()).format("YYYY-MM-DD-HHmmss");

                var filename = 'Report_Maintenance_' + dateNow + '.xlsx';
                var ws = XLSX.utils.json_to_sheet(data, {skipHeader: true});
                ws['!cols'] = MaintenanceReportServices.maintenanceReportExcelHeaderSize();

                var wb = XLSX.utils.book_new();

                XLSX.utils.book_append_sheet(wb, ws);
                XLSX.writeFile(wb, filename);
            }
        },
    },
}
</script>