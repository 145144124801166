import gql from 'graphql-tag';
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class MaintenanceService {
    readQuery(){
        var query = `
            query ($startDate:String!, $endDate:String!, $machineId:Int!) {
                GetMaintenanceReport(start_date:$startDate, end_date:$endDate, machine_id:$machineId) {
                    maintenance_code
                    maintenance_type
                    downtime
                    maintenance_date
                    maintenance_end_date
                    notes
                    product_code
                    item_name
                    quantity
                    type_detail
                    approved_by
                } 
            }
        `;
        return query;
    }

    async getMachineQuery(){
        var query = gql`
            query {
                GetMachine {
                    machine {
                        machine_id
                        machine_code
                        machine_name
                    }
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];

        if (result.data.GetMachine != null) {
            for (let i = 0; i < result.data.GetMachine.machine.length; i++) {
                var str = { 
                    value: result.data.GetMachine.machine[i].machine_id, 
                    label: result.data.GetMachine.machine[i].machine_code + ' (' + result.data.GetMachine.machine[i].machine_name + ')'
                }
                arrayData.push(str);
            }
        }
        return arrayData;
    }   

    maintenanceReportExcelHeaderSize(){
        var size = [
            {width: 25}, //colA
            {width: 25}, //colB
            {width: 25}, //colC
            {width: 25}, //colD
            {width: 25}, //colE
            {width: 25}, //colF
            {width: 25}, //colG
            {width: 25}, //colH
            {width: 25}, //colI
            {width: 25}, //colJ
            {width: 25}, //colK
        ];
        return size;
    }

    maintenanceReportExcel(data, variables){
        var arrayObject = [];
        var date = 'Tanggal ' + moment(variables.startDate).format('DD MMM YYYY') + ' - ' + moment(variables.endDate).format('DD MMM YYYY');
        var numberFormat = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"_);_(@_)';

        var startIndex = 6;
        var lastIndex = startIndex;

        if(data != null){
            lastIndex = lastIndex + data.length - 1;
        }

        //Header
        var row1 = {colA : 'PT BOJONG WESTPLAS',};
        arrayObject.push(row1);

        var row2 = {colA : 'Maintenance Report',};
        arrayObject.push(row2);

        var row3 = {colA : date};
        arrayObject.push(row3);

        var spaceRow = {colA : null};
        arrayObject.push(spaceRow);

        var row6 = {
            colA : 'KODE MAINTENANCE',
            colB : 'TIPE MAINTENANCE',
            colC : 'DOWNTIME',
            colD : 'TANGGAL MAINTENANCE',
            colE : 'TANGGAL SELESAI MAINTENANCE',
            colF : 'KODE BARANG',
            colG : 'NAMA BARANG',
            colH : 'QUANTITY',
            colI : 'TIPE DETAIL',
            colJ : 'CATATAN',
            colK : 'APPROVE OLEH',
        };
        arrayObject.push(row6);

        if(data != null){
            for (let i = 0; i < data.length; i++){
                var row = {
                    colA : data[i].maintenance_code,
                    colB : data[i].maintenance_type,
                    colC : { v: data[i].downtime, z: numberFormat },
                    colD : kendo.toString(kendo.parseDate(data[i].maintenance_date, 'yyyy-MM-dd'), 'dd-MM-yyyy'),
                    colE : kendo.toString(kendo.parseDate(data[i].maintenance_end_date, 'yyyy-MM-dd'), 'dd-MM-yyyy'),
                    colF : data[i].product_code,
                    colG : data[i].item_name,
                    colH : { v: data[i].quantity, z: numberFormat },
                    colI : data[i].type_detail,
                    colJ : data[i].notes,
                    colK : data[i].approved_by,
                };
                
                arrayObject.push(row);
            }
        }

        return arrayObject;
    }
}


export default new MaintenanceService();